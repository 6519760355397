.navbar_container {
  z-index: 1000;
  padding: 3rem 0rem;
  padding-bottom: 1rem;
  position: absolute;
  top: 50px;
}
.navbar_container .menu_container .menu_items .menu {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  background-color: rgba(76, 61, 156, 0.8);
  padding: 10px 20px;
  border-radius: 10px;
}

button {
  cursor: pointer;
}

.footer_container {
  min-height: 60vh;
  background-color: #4c3d9c;
}
.footer_container .section_footer {
  padding-top: 50px;
  color: #fff;
}
.footer_container .section_footer .footer_heading {
  font-size: 2rem;
  font-weight: 800;
  letter-spacing: 1.2px;
}
.footer_container .section_footer .section_content p {
  font-size: 25px;
}
.footer_container .section_footer .section_content ul li {
  font-size: 25px;
  font-weight: 800;
  margin-bottom: 20px;
}
.footer_container .section_footer .section_content img {
  width: 35px;
}

.menu_icon,
.close {
  width: 2em !important;
  height: 2em !important;
}

.top_bar {
  background-color: #38b492;
  padding: 10px 0;
}
.top_bar .email,
.top_bar .mobile {
  font-weight: bold;
  font-size: 1.3rem;
  color: #fff;
}
.top_bar .email img,
.top_bar .mobile img {
  width: 30px;
  margin-right: 20px;
}
.top_bar .email {
  margin-right: 30px;
}

.section {
  min-height: 80vh;
  padding-top: 150px;
}

.blog_page_container {
  padding-top: 150px;
}

.banner_carousel {
  position: relative;
  overflow-y: hidden;
}
.banner_carousel .banner_text_container {
  width: 45%;
  position: absolute;
  height: calc(100% - 36px);
  background-color: rgba(76, 61, 156, 0.5);
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.banner_carousel .banner_text_container h1 {
  font-size: 3.5rem;
  color: #fff;
  font-weight: bold;
  padding: 30px;
}
.banner_carousel .banner_text_container p {
  font-size: 1.8rem;
  color: #fff;
  padding: 30px;
}

.banner_container .banner_img_container {
  height: auto;
}
.banner_container .banner_img_container .card {
  width: 45%;
  box-shadow: 2px -2px 18px 5px rgba(0, 0, 0, 0.45), 25px 0px 20px -20px rgba(0, 0, 0, 0.45), 0px 25px 20px -20px rgba(0, 0, 0, 0.45), -25px 0px 20px -20px rgba(0, 0, 0, 0.45);
  min-height: 60vh;
}
.banner_container .banner_img_container .card .name {
  font-size: 2rem;
  margin-top: 20px;
}
.banner_container .banner_img_container .card .description {
  font-size: 1.1rem;
  margin-top: 20px;
}
.banner_container .banner_img_container .accord_subservice .text-xl {
  font-size: 1.2rem;
  font-weight: 700;
}
.banner_container .banner_img_container .accord_subservice .text-xl span {
  font-size: 1rem;
}
.banner_container .banner_text_container .brand_name {
  font-size: 4rem;
  font-weight: bold;
  color: #4c3d9c;
}
.banner_container .banner_text_container .text {
  margin-top: 20px;
  font-size: 1.8rem;
}
.banner_container .banner_text_container .experience,
.banner_container .banner_text_container .count {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 50px;
  line-height: 1;
}
.banner_container .banner_text_container .experience span,
.banner_container .banner_text_container .count span {
  font-size: 4.5rem;
}
.banner_container .banner_text_container .appointment_btn {
  padding: 15px 30px;
  background-color: #38b492;
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  border-radius: 30px;
  margin-top: 50px;
}
.banner_container .plus_img {
  top: -40px;
}

.about_container .text_container {
  min-height: 45vh;
}
.about_container .text_container .title {
  font-size: 3rem;
  font-weight: 700;
  color: #4c3d9c;
}
.about_container .text_container .about_paragraph {
  font-size: 1.8rem;
  font-weight: 500;
  width: 65%;
}

.service_container {
  padding: 100px 0;
}
.service_container .accord_subservice {
  border-radius: 20px !important;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.25);
}
.service_container .accord_subservice .MuiAccordionSummary-content {
  align-items: center;
}
.service_container .accord_subservice .MuiAccordionSummary-content p {
  font-size: 1.3rem;
  font-weight: 550;
  color: #808080;
  transition: 0.3s ease all;
  padding-left: 30px;
}
.service_container .accord_subservice .MuiAccordionSummary-content:hover p {
  color: #000;
  transition: 0.3s ease all;
}
.service_container .accord_subservice::before {
  display: none;
}
.service_container .accord_subservice .link {
  transition: 0.3s ease all;
}
.service_container .accord_subservice .link a {
  font-size: 1.2rem;
  color: #000;
}
.service_container .accord_subservice .link:hover {
  background-color: #121212;
  transition: 0.3s ease all;
}
.service_container .accord_subservice .link:hover a {
  color: #fff;
}

.section .heading_container {
  text-align: center;
  margin-bottom: 30px;
}
.section .heading_container h1 {
  font-size: 5rem;
  font-weight: 700;
}
.section .heading_container p {
  font-size: 2rem;
  margin-top: 10px;
}

.testimonial_container {
  padding: 100px 0;
}
.testimonial_container .testimonal_card_container .testimonial_card {
  box-shadow: 4px 3px 20px -6px rgba(0, 0, 0, 0.75);
  border-radius: 45px;
  min-height: 400px;
}
.testimonial_container .testimonal_card_container .testimonial_card .name {
  font-size: 1.2rem;
  color: #4c3d9c;
  font-weight: bold;
  margin-top: 30px;
}
.testimonial_container .testimonal_card_container .owl-item {
  padding: 20px;
}

.services_main_container .services_container,
.subservice_container .services_container {
  margin-bottom: 50px;
}
.services_main_container .services_container .left .appointment_btn,
.subservice_container .services_container .left .appointment_btn {
  padding: 15px 30px;
  background-color: #38b492;
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  border-radius: 30px;
  margin-top: 50px;
}
.services_main_container .services_container .left .title h1,
.subservice_container .services_container .left .title h1 {
  font-size: 3rem;
  font-weight: bold;
}
.services_main_container .services_container .left .description,
.subservice_container .services_container .left .description {
  font-size: 1.3rem;
}
.services_main_container .services_container:nth-child(2n) .left,
.subservice_container .services_container:nth-child(2n) .left {
  text-align: left;
}
.services_main_container .services_container:nth-child(2n-1) .left,
.subservice_container .services_container:nth-child(2n-1) .left {
  text-align: left;
}
.services_main_container .subservice_title,
.subservice_container .subservice_title {
  font-size: 3rem;
  font-weight: bold;
  color: #4c3d9c;
}

.team_section {
  padding: 100px 0;
}
.team_section .team_container {
  text-align: center;
}
.team_section .team_container .card {
  width: 400px;
  background-color: #808080;
  margin: auto;
  margin-bottom: 50px;
  border-radius: 20px !important;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.contact_section .contact_card {
  width: 80%;
  min-height: 80vh;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  overflow: hidden;
  margin-top: 100px;
  margin-bottom: 100px;
}
.contact_section .contact_card .left {
  min-height: 80vh;
  width: 50%;
  background-color: rgba(76, 61, 156, 0.8);
  padding: 40px;
}
.contact_section .contact_card .left .text {
  margin-left: 50px;
  color: #fff;
}
.contact_section .contact_card .left .text p {
  font-size: 1.2rem;
}
.contact_section .contact_card .left .timing {
  width: 90%;
}
.contact_section .contact_card .left .timing .week {
  color: #fff;
  justify-content: space-between;
}
.contact_section .contact_card .right {
  width: 50%;
}
.contact_section .contact_card .right .appointment_btn {
  padding: 15px 30px;
  background-color: #38b492;
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  border-radius: 30px;
  margin-top: 50px;
}
.contact_section .contact_card .right .fields {
  min-height: 80vh;
}

.blog__section_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.blog__section_container .blog_header_container {
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%;
}
.blog__section_container .blog_header_container .tip_container {
  margin: 30px 0;
}
.blog__section_container .blog_header_container .tip_container p {
  font-size: 1.5rem;
}
.blog__section_container .blog_header_container .heading_container {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  perspective: 900px;
  transform-style: preserve-3d;
}
.blog__section_container .blog_header_container .heading_container h1 {
  text-align: center;
  font-size: 3.5rem;
  line-height: 1.1;
  font-weight: bold;
}
.blog__section_container .blog_post_container {
  width: 85%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 50px;
  margin-top: 50px;
  margin-bottom: 100px;
  text-align: center;
}
.blog__section_container .blog_post_container > div {
  min-width: 350px;
  margin: 30px;
}
.blog__section_container .blog_post_container > div .tile {
  width: 100%;
  height: 350px;
  justify-self: center;
  position: relative;
  margin: auto;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;
}
.blog__section_container .blog_post_container > div .tile:nth-child(3n+2) {
  transform: translateY(50px);
}
.blog__section_container .blog_post_container > div .tile .grid_container {
  right: -30px;
  top: -20px;
  width: 80%;
  height: 80%;
  z-index: 2;
}
.blog__section_container .blog_post_container > div .tile .grid_container img {
  width: 100%;
}
.blog__section_container .blog_post_container > div .title_container {
  max-width: 100%;
  margin: auto;
}
.blog__section_container .blog_post_container > div .title_container .tag {
  text-align: center;
}
.blog__section_container .blog_post_container > div .title_container .tag p {
  padding: 5px 7px;
  border-radius: 14px;
  border: 2px solid black;
  display: inline-block;
  font-size: 2rem;
}
.blog__section_container .blog_post_container > div .title_container .title {
  font-size: 1.8rem;
  font-weight: 600;
}
.blog__section_container .counter__counter {
  margin-bottom: 50px;
  width: 90%;
}

.react-datepicker-wrapper {
  width: 80%;
  padding: 10px 25px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 5px;
}
.react-datepicker-wrapper .Date_picker:focus-visible {
  outline: none;
}

.react-datepicker__tab-loop {
  z-index: 10;
}

.blog_detail {
  width: 80%;
  align-items: flex-start;
  justify-content: space-around;
  margin-top: 50px;
}
.blog_detail .blog_detail_section {
  max-width: 60%;
}
.blog_detail .sidebar_container {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 30px;
  position: sticky;
  top: 10%;
}
.blog_detail .sidebar_container .author_container {
  text-align: center;
}
.blog_detail .sidebar_container .author_container .author_image {
  margin-bottom: 10px;
}
.blog_detail .sidebar_container .author_container .author_image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: auto;
}
.blog_detail .sidebar_container .author_container .author_name h3 {
  font-size: 1.5rem;
  font-weight: bold;
}
.blog_detail .sidebar_container .author_container .author_name p {
  max-width: 75%;
  margin: auto;
}
.blog_detail .sidebar_container .next {
  margin-top: 50px;
}
.blog_detail .sidebar_container .next h4 {
  font-size: 1.2rem;
  font-weight: bold;
  border-bottom: 1px solid #000;
  text-align: center;
}
.blog_detail .blog_title {
  font-size: 3rem;
}
.blog_detail .description h1 {
  font-size: 2.5rem;
}
.blog_detail .description p {
  margin: 25px 0;
  font-size: 1.5rem;
}
.blog_detail .description p img {
  display: inline-block;
}
.blog_detail .description h2 {
  font-size: 2rem;
}
.blog_detail .description h4,
.blog_detail .description h3 {
  font-size: 1.8rem;
}
.blog_detail .description ol {
  list-style: decimal;
  margin: 25px 0;
}
.blog_detail .description ol li {
  margin: 10px 0;
  font-size: 1.5rem;
}

.btn_container {
  position: relative;
}
.btn_container .drop-down {
  margin: 20px;
  position: absolute;
  height: 0;
  transition: 0.3s ease all;
}
.btn_container .drop-down p {
  font-weight: bold;
  padding-bottom: 5px;
}
.btn_container:hover .drop-down {
  height: 200px;
  transition: 0.3s ease all;
}

@media screen and (max-width: 1680px) {
  .banner_container {
    width: 95%;
  }
  .navbar_container .menus {
    width: 60%;
  }
}
@media screen and (max-width: 1536px) {
  .navbar_container .logo_container img {
    width: 30%;
  }
  .about_container .text_container .about_paragraph {
    width: 75%;
  }
  .section .heading_container h1 {
    font-size: 4rem;
  }
  .service_container .services_dropdown {
    width: 90%;
  }
  .service_container .services_dropdown .accord_subservice {
    width: 45%;
  }
  .about_banner_section .text_container p {
    width: 90%;
  }
  .about_banner_section .text_container h1 {
    font-size: 3.5rem;
  }
  .team_section h1 {
    margin-bottom: 30px;
    font-size: 3.5rem;
  }
  .team_section .team_container {
    width: 90%;
  }
  .banner_container .banner_text_container {
    width: 35%;
  }
  .banner_container .banner_text_container .brand_name {
    font-size: 3rem;
  }
  .banner_container .banner_img_container {
    width: 65%;
  }
  .banner_container .banner_img_container .cards_container .card {
    width: 48%;
  }
}
@media screen and (max-width: 1367px) {
  .navbar_container {
    width: 95%;
  }
  .navbar_container .menus {
    width: 70%;
  }
  .footer_container {
    padding: 0;
  }
  .contact_section .contact_card {
    width: 90%;
  }
  .section.banner_container {
    min-height: 80vh;
  }
  .section.banner_container .banner_img_container {
    width: 65%;
  }
  .section.banner_container .banner_text_container {
    padding-left: 3.5rem;
  }
  .section.banner_container .banner_text_container .experience span,
  .section.banner_container .banner_text_container .count span {
    font-size: 3.5rem;
  }
  .section.banner_container .banner_text_container .brand_name {
    font-size: 2rem;
  }
  .banner_carousel .banner_text_container {
    width: 60%;
  }
}
@media screen and (max-width: 1280px) {
  .navbar_container {
    padding: 4rem 0rem;
    padding-bottom: 1rem;
  }
  .navbar_container .menu_container .menu_items .menu {
    font-size: 15px;
  }
  .section.banner_container {
    min-height: 60vh;
    align-items: center;
    margin-top: 50px;
  }
  .banner_carousel .banner_text_container h1 {
    font-size: 2.5rem;
  }
  .banner_carousel .banner_text_container p {
    font-size: 1.2rem;
    padding: 10px 30px;
  }
  .banner_container {
    padding: 0 !important;
  }
  .banner_container .banner_text_container {
    padding-left: 7%;
  }
  .about_banner_section {
    flex-direction: column;
  }
  .about_banner_section .text_container {
    width: 90%;
    text-align: center;
  }
  .about_banner_section .text_container p {
    width: 90%;
    margin: auto;
  }
  .about_banner_section .text_container h1 {
    font-size: 3.5rem;
  }
  .about_banner_section img {
    margin: 0;
    margin-bottom: 30px;
    width: 70%;
  }
}
@media screen and (max-width: 1180px) {
  .section.banner_container {
    flex-direction: column;
    justify-content: center;
  }
  .section.banner_container .banner_text_container {
    padding: 30px;
    width: 100%;
    text-align: center;
  }
  .section.banner_container .banner_text_container .experience,
  .section.banner_container .banner_text_container .count {
    margin-top: 20px;
  }
  .section.banner_container .banner_img_container {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .navbar_container .menu_container {
    width: 100%;
  }
  .section.banner_container .banner_text_container .experience span,
  .section.banner_container .banner_text_container .count span {
    font-size: 2.5rem;
  }
  .section.banner_container .banner_text_container .appointment_btn {
    font-size: 1rem;
  }
}
@media screen and (max-width: 992px) {
  .App {
    max-width: 100vw;
    overflow-x: hidden;
  }
  .banner_carousel .banner_text_container {
    width: 70%;
  }
  .banner_carousel .banner_text_container h1 {
    font-size: 2rem;
  }
  .banner_carousel .banner_text_container p {
    font-size: 1.2rem;
    padding: 10px 30px;
  }
  .navbar_container {
    position: relative;
    padding: 2rem 0;
  }
  .navbar_container .menus {
    position: absolute;
    top: 0;
    right: -100%;
    width: 350px;
    height: 100vh;
    background-color: #38b492;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transition: 0.5s all ease;
  }
  .navbar_container .menus .menu_container {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 30px;
    width: 100%;
    height: 40%;
    margin-top: 20px;
  }
  .navbar_container .menus .menu_container .menu_items {
    border-bottom: 1px solid white;
    width: 100%;
  }
  .navbar_container .menus .menu_container .menu_items a {
    font-size: 1.5rem;
    color: #000;
    background-color: transparent;
  }
  .navbar_container .menus .closeIcon {
    margin-right: 40px;
  }
  .navbar_container .menu--open {
    right: -25px;
    transition: 0.5s all ease;
  }
  .navbar_container .logo_container {
    width: 30%;
  }
  .footer_container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .footer_container .section_footer {
    width: 80%;
  }
  .footer_container .section_content {
    width: 100%;
  }
  .footer_container .section_content .footer_menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .footer_container .section_content .footer_menu .footer_link {
    margin: 20px;
  }
  .section.banner_container {
    flex-direction: column;
    justify-content: center;
  }
  .section.banner_container .banner_text_container {
    padding: 30px;
    width: 100%;
    text-align: center;
  }
  .section.banner_container .banner_text_container .experience,
  .section.banner_container .banner_text_container .count {
    margin-top: 20px;
  }
  .section.banner_container .banner_img_container {
    width: 100%;
  }
  .about_container .text_container .about_paragraph {
    width: 90%;
  }
  .section .heading_container h1 {
    font-size: 3rem;
  }
  .section .heading_container p {
    font-size: 1.5rem;
    padding: 0 10px;
  }
  .service_container .services_dropdown {
    width: 90%;
  }
  .service_container .services_dropdown .accord_subservice {
    width: 90%;
  }
  .services_main_container .services_container {
    flex-direction: column-reverse !important;
    width: 100%;
    padding: 1rem;
  }
  .services_main_container .services_container .left,
  .services_main_container .services_container .right {
    width: 100%;
    text-align: center !important;
  }
  .subservices_container {
    flex-direction: column-reverse !important;
  }
  .subservices_container .left,
  .subservices_container .right {
    width: 100%;
    text-align: center !important;
  }
  .contact_section .contact_card {
    flex-direction: column;
  }
  .contact_section .contact_card .left,
  .contact_section .contact_card .right {
    width: 100%;
  }
  .team_section .team_container {
    width: 95%;
  }
}
@media screen and (max-width: 768px) {
  .about_container .text_container {
    justify-content: center;
  }
  .about_container .text_container .title {
    font-size: 2.5rem;
    margin-bottom: 50px;
  }
  .about_container .text_container .about_paragraph {
    font-size: 1.6rem;
  }
  .team_section h1 {
    padding: 0 10px;
    text-align: center;
    font-size: 3rem;
    margin-bottom: 80px;
  }
  .team_section .team_container {
    flex-direction: column;
    align-items: center;
  }
  .team_section .team_container .team {
    width: 90%;
    padding: 0;
    margin-bottom: 30px;
  }
  .services_main_container .services_container .subservice_title,
  .subservice_container .services_container .subservice_title {
    font-size: 2.5rem;
    text-align: center;
  }
  .services_main_container .services_container .left .title h1,
  .subservice_container .services_container .left .title h1 {
    font-size: 2rem;
  }
  .section.banner_container {
    padding: 0;
  }
}
@media screen and (max-width: 640px) {
  .achivement {
    width: 60%;
  }
  .achivement .image {
    flex: 1;
  }
  .achivement .text {
    flex: 1;
  }
  .section.banner_container .banner_img_container .cards_container {
    flex-direction: column;
  }
  .section.banner_container .banner_img_container .cards_container .card {
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }
  .section.banner_container .banner_img_container .cards_container .card img {
    width: 100%;
  }
}
@media screen and (max-width: 580px) {
  .navbar_container {
    padding: 2rem 1rem;
  }
  .navbar_container .logo_container {
    width: 50%;
  }
  .navbar_container .logo_container img {
    width: 40%;
  }
  .banner_carousel .banner_text_container {
    display: none;
  }
  .about_container .text_container {
    justify-content: center;
  }
  .about_container .text_container .title {
    font-size: 2rem;
    margin-bottom: 50px;
    padding: 0px 10px;
  }
  .about_container .text_container .about_paragraph {
    font-size: 1.6rem;
  }
  .footer_container .section_footer {
    width: 90%;
  }
  .footer_container .section_footer:last-child {
    padding-bottom: 50px;
  }
  .achivement {
    width: 80%;
  }
  .achivement .image {
    flex: 1;
  }
  .achivement .text {
    flex: 1;
  }
  .contact_section h1 {
    font-size: 3.5rem;
  }
  .contact_section .contact_card .left {
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
  .contact_section .contact_card .left .timing {
    width: 100%;
  }
  .contact_section .contact_card .left .text {
    margin-left: 0;
    margin-top: 10px;
  }
  .contact_section .contact_card .contact,
  .contact_section .contact_card .email,
  .contact_section .contact_card .address {
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
  }
  .contact_section .contact_card .timing h2 {
    text-align: center;
  }
  .contact_section .contact_card .timing .week {
    flex-direction: column;
  }
  .contact_section .contact_card .timing .week p {
    width: 100%;
  }
  .subservices_container {
    padding-left: 0;
    padding-right: 0;
  }
  .top_bar .announcement_bar {
    justify-content: space-between;
    width: 95%;
  }
  .top_bar .announcement_bar .email,
  .top_bar .announcement_bar .mobile {
    font-size: 1rem;
  }
  .top_bar .announcement_bar .email img,
  .top_bar .announcement_bar .mobile img {
    width: 20px;
    margin-right: 10px;
  }
}
@media screen and (min-width: 992px) {
  .hamburger_menu {
    display: none;
  }
  .closeIcon {
    display: none;
  }
}/*# sourceMappingURL=Styles.css.map */