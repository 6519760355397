.navbar_container {
  z-index: 1000;
  padding: 3rem 0rem;
  padding-bottom: 1rem;
  position: absolute;
  top: 50px;
  .menu_container {
    .menu_items {
      .menu {
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff;
        background-color: rgba($color: #4c3d9c, $alpha: 0.8);
        padding: 10px 20px;
        border-radius: 10px;
      }
    }
  }
}

button {
  cursor: pointer;
}

.footer_container {
  min-height: 60vh;
  background-color: #4c3d9c;

  .section_footer {
    padding-top: 50px;
    color: #fff;
    .footer_heading {
      font-size: 2rem;
      font-weight: 800;
      letter-spacing: 1.2px;
    }

    .section_content {
      p {
        font-size: 25px;
      }

      ul {
        li {
          font-size: 25px;
          font-weight: 800;
          margin-bottom: 20px;
        }
      }

      img {
        width: 35px;
      }
    }
  }
}

.menu_icon,
.close {
  width: 2em !important;
  height: 2em !important;
}

.top_bar {
  background-color: #38b492;
  padding: 10px 0;
  .email,
  .mobile {
    font-weight: bold;
    font-size: 1.3rem;
    color: #fff;
    img {
      width: 30px;
      margin-right: 20px;
    }
  }

  .email {
    margin-right: 30px;
  }
}

// Homepage Banner Styles Start

.section {
  // width: 100%;
  min-height: 80vh;
  padding-top: 150px;
}

.blog_page_container{
  padding-top: 150px;
}


.banner_carousel{
  position: relative;
  overflow-y: hidden;
  // transform: translateY(-140px);
  .banner_text_container{
    width: 45%;
    position: absolute;
    height: calc(100% - 36px);
    background-color: rgba($color: #4c3d9c, $alpha: 0.5);
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    h1{
      font-size: 3.5rem;
      color: #fff;
      font-weight: bold;
      padding: 30px;
    }

    p{
      font-size: 1.8rem;
      color: #fff;
      padding: 30px;
    }
  }
}

.banner_container {
  .banner_img_container {
    height: auto;

    .card {
      width: 45%;
      box-shadow: 2px -2px 18px 5px rgba(0, 0, 0, 0.45),
        25px 0px 20px -20px rgba(0, 0, 0, 0.45),
        0px 25px 20px -20px rgba(0, 0, 0, 0.45),
        -25px 0px 20px -20px rgba(0, 0, 0, 0.45);
      min-height: 60vh;

      .name {
        font-size: 2rem;
        margin-top: 20px;
      }

      .description {
        font-size: 1.1rem;
        margin-top: 20px;
      }
    }

    .accord_subservice {
      .text-xl {
        font-size: 1.2rem;
        font-weight: 700;

        span {
          font-size: 1rem;
        }
      }
    }
  }

  .banner_text_container {
    // margin-top: 200px;
    // padding-left: 7%;

    .brand_name {
      font-size: 4rem;
      font-weight: bold;
      color: #4c3d9c;
    }

    .text {
      margin-top: 20px;
      font-size: 1.8rem;
    }

    .experience,
    .count {
      font-size: 1rem;
      font-weight: bold;
      margin-top: 50px;
      line-height: 1;

      span {
        font-size: 4.5rem;
      }
    }

    .appointment_btn {
      padding: 15px 30px;
      background-color: #38b492;
      font-size: 1.5rem;
      font-weight: 600;
      color: #fff;
      border-radius: 30px;
      margin-top: 50px;
    }
  }

  .plus_img {
    top: -40px;
  }
}

.about_container {
  .text_container {
    min-height: 45vh;
    .title {
      font-size: 3rem;
      font-weight: 700;
      color: #4c3d9c;
    }

    .about_paragraph {
      font-size: 1.8rem;
      font-weight: 500;
      width: 65%;
    }
  }
}

.service_container {
  padding: 100px 0;
  .accord_subservice {
    border-radius: 20px !important;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.25);
    .MuiAccordionSummary-content {
      align-items: center;

      p {
        font-size: 1.3rem;
        font-weight: 550;
        color: #808080;
        transition: 0.3s ease all;
        padding-left: 30px;
      }

      &:hover {
        p {
          color: #000;
          transition: 0.3s ease all;
        }
      }
    }

    &::before {
      display: none;
    }

    .link {
      transition: 0.3s ease all;
      a {
        font-size: 1.2rem;
        color: #000;
      }
      &:hover {
        background-color: #121212;
        transition: 0.3s ease all;
        a {
          color: #fff;
        }
      }
    }
  }
}

.section {
  .heading_container {
    text-align: center;
    margin-bottom: 30px;
    h1 {
      font-size: 5rem;
      font-weight: 700;
    }

    p {
      font-size: 2rem;
      margin-top: 10px;
    }
  }
}

.testimonial_container {
  padding: 100px 0;
  .testimonal_card_container {
    .testimonial_card {
      -webkit-box-shadow: 4px 3px 20px -6px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 4px 3px 20px -6px rgba(0, 0, 0, 0.75);
      box-shadow: 4px 3px 20px -6px rgba(0, 0, 0, 0.75);
      border-radius: 45px;
      min-height: 400px;
      .name {
        font-size: 1.2rem;
        color: #4c3d9c;
        font-weight: bold;
        margin-top: 30px;
      }
    }

    .owl-item {
      padding: 20px;
    }
  }
}

// Service Page Styles

.services_main_container,
.subservice_container {
  // margin-top: 200px;

  .services_container {
    margin-bottom: 50px;
    .left {
      .appointment_btn {
        padding: 15px 30px;
        background-color: #38b492;
        font-size: 1.5rem;
        font-weight: 600;
        color: #fff;
        border-radius: 30px;
        margin-top: 50px;
      }
      .title {
        h1 {
          font-size: 3rem;
          font-weight: bold;
        }
      }
      .description {
        font-size: 1.3rem;
      }
    }

    &:nth-child(2n) {
      .left {
        text-align: left;
      }
    }

    &:nth-child(2n-1) {
      .left {
        text-align: left;
      }
    }
  }

  .subservice_title {
    font-size: 3rem;
    font-weight: bold;
    color: #4c3d9c;
  }
}

.team_section {
  padding: 100px 0;
  .team_container {
    text-align: center;
    .card {
      width: 400px;
      // height: 400px;
      background-color: #808080;
      margin: auto;
      margin-bottom: 50px;
      border-radius: 20px !important;
      -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.25);
      box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.25);
      overflow: hidden;
    }
  }
}

.contact_section {
  // margin-top: 150px;
  .contact_card {
    width: 80%;
    min-height: 80vh;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    overflow: hidden;
    margin-top: 100px;
    margin-bottom: 100px;

    .left {
      min-height: 80vh;
      width: 50%;
      // background: #4c3d9c;
      background-color: rgba($color: #4c3d9c, $alpha: 0.8);
      padding: 40px;

      .text {
        margin-left: 50px;
        color: #fff;
        p {
          font-size: 1.2rem;
        }
      }

      .timing {
        width: 90%;
        .week {
          color: #fff;
          justify-content: space-between;
        }
      }
    }

    .right {
      width: 50%;

      .appointment_btn {
        padding: 15px 30px;
        background-color: #38b492;
        font-size: 1.5rem;
        font-weight: 600;
        color: #fff;
        border-radius: 30px;
        margin-top: 50px;
      }

      .fields {
        min-height: 80vh;
      }
    }
  }
}

// Blogs Page
.blog_page_container {
}

.blog__section_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .blog_header_container {
    // min-height: 60vh;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 50%;

    .tip_container {
      margin: 30px 0;
      p {
        font-size: 1.5rem;
      }
    }

    .heading_container {
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      perspective: 900px;
      transform-style: preserve-3d;
      h1 {
        text-align: center;
        font-size: 3.5rem;
        line-height: 1.1;
        font-weight: bold;
      }
    }
  }

  .blog_post_container {
    width: 85%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 50px;
    margin-top: 50px;
    margin-bottom: 100px;
    text-align: center;
    > div {
      min-width: 350px;
      margin: 30px;
      .tile {
        width: 100%;
        height: 350px;
        justify-self: center;
        position: relative;
        margin: auto;
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.25);
        box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.25);
        margin-bottom: 20px;

        &:nth-child(3n + 2) {
          transform: translateY(50px);
        }

        .grid_container {
          // position: absolute;
          right: -30px;
          top: -20px;
          width: 80%;
          height: 80%;
          z-index: 2;
          img {
            width: 100%;
          }
        }
      }

      .title_container {
        max-width: 100%;
        margin: auto;
        .tag {
          text-align: center;
          p {
            padding: 5px 7px;
            border-radius: 14px;
            border: 2px solid black;
            display: inline-block;
            font-size: 2rem;
          }
        }

        .title {
          font-size: 1.8rem;
          font-weight: 600;
        }
      }
    }
  }

  .counter__counter {
    margin-bottom: 50px;
    width: 90%;
  }
}

.react-datepicker-wrapper {
  width: 80%;
  padding: 10px 25px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 5px;

  .Date_picker {
    &:focus-visible {
      outline: none;
    }
  }
}

.react-datepicker__tab-loop {
  z-index: 10;
}

.blog_detail {
  width: 80%;
  align-items: flex-start;
  justify-content: space-around;
  margin-top: 50px;
  .blog_detail_section {
    max-width: 60%;
  }

  .sidebar_container {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 30px;
    position: sticky;
    top: 10%;
    .author_container {
      text-align: center;
      .author_image {
        margin-bottom: 10px;
        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          margin: auto;
        }
      }

      .author_name{
        h3{
          font-size: 1.5rem;
          font-weight: bold;
        }

        p{
          max-width: 75%;
          margin: auto;
        }
      }
    }

    .next{
      margin-top: 50px;
      h4{
        font-size: 1.2rem;
        font-weight: bold;
        border-bottom: 1px solid #000;
        text-align: center;
      }
    }
  }

  .blog_title {
    font-size: 3rem;
  }

  .description {

    h1{
      font-size: 2.5rem;
    }

    p {
      margin: 25px 0;
      font-size: 1.5rem;

      img{
        display: inline-block;
      }
    }

    h2 {
      font-size: 2rem;
    }

    h4,
    h3 {
      font-size: 1.8rem;
    }

    ol {
      list-style: decimal;
      margin: 25px 0;
      li {
        margin: 10px 0;
        font-size: 1.5rem;
      }
    }
  }
}

.btn_container {
  position: relative;
  .drop-down {
    margin: 20px;
    position: absolute;
    height: 0;
    transition: 0.3s ease all;
    p {
      font-weight: bold;
      padding-bottom: 5px;
    }
  }

  &:hover {
    .drop-down {
      height: 200px;
      transition: 0.3s ease all;
    }
  }
}

// Media Query
@import "./media";
