@media screen and (max-width: 1680px) {
  .banner_container {
    width: 95%;
  }

  .navbar_container{
    .menus{
      width: 60%;
    }
  }
}

@media screen and (max-width: 1536px) {
  .navbar_container {
    .logo_container {
      img {
        width: 30%;
      }
    }
  }

  .about_container {
    .text_container {
      .about_paragraph {
        width: 75%;
      }
    }
  }

  .section {
    .heading_container {
      h1 {
        font-size: 4rem;
      }
    }
  }

  .service_container {
    .services_dropdown {
      width: 90%;
      .accord_subservice {
        width: 45%;
      }
    }
  }

  .about_banner_section {
    .text_container {
      p {
        width: 90%;
      }
      h1 {
        font-size: 3.5rem;
      }
    }
  }

  .team_section {
    h1 {
      margin-bottom: 30px;
      font-size: 3.5rem;
    }
    .team_container {
      width: 90%;
    }
  }

  .banner_container {
    .banner_text_container {
      width: 35%;
      .brand_name {
        font-size: 3rem;
      }
    }

    .banner_img_container {
      width: 65%;
      .cards_container {
        .card {
          width: 48%;
        }
      }
    }
  }
}

@media screen and (max-width: 1367px) {

  .navbar_container{
    width: 95%;
    .menus{
      width: 70%;
    }
  }

  .footer_container {
    padding: 0;
  }

  // .testimonal_card_container {
  //   width: 90%;
  // }

  .contact_section {
    .contact_card {
      width: 90%;
    }
  }

  .section.banner_container {
    min-height: 80vh;

    .banner_img_container {
      width: 65%;
    }

    .banner_text_container {
      padding-left: 3.5rem;
      .experience,
      .count {
        span {
          font-size: 3.5rem;
        }
      }

      .brand_name {
        font-size: 2rem;
      }
    }
  }

  .banner_carousel {
    .banner_text_container {
      width: 60%;
    }
  }
}

@media screen and (max-width: 1280px) {
  .navbar_container {
    padding: 4rem 0rem;
    padding-bottom: 1rem;

    .menu_container{
      .menu_items{
        .menu{
          font-size: 15px;
        }
      }
    }
  }

  .section.banner_container {
    min-height: 60vh;
    align-items: center;
    margin-top: 50px;
  }

  .banner_carousel {
    .banner_text_container {
      h1 {
        font-size: 2.5rem;
      }

      p {
        font-size: 1.2rem;
        padding: 10px 30px;
      }
    }
  }

  .banner_container {
    padding: 0 !important;
    .banner_text_container {
      padding-left: 7%;
    }
  }

  // .testimonial_container {
  //   .testimonal_card_container {
  //     flex-wrap: wrap;
  //   }
  // }

  .about_banner_section {
    flex-direction: column;
    .text_container {
      width: 90%;
      text-align: center;
      p {
        width: 90%;
        margin: auto;
      }
      h1 {
        font-size: 3.5rem;
      }
    }

    img {
      margin: 0;
      margin-bottom: 30px;
      width: 70%;
    }
  }
}

@media screen and (max-width: 1180px) {
  .section.banner_container {
    flex-direction: column;
    justify-content: center;
    .banner_text_container {
      padding: 30px;
      width: 100%;
      text-align: center;
      .experience,
      .count {
        margin-top: 20px;
      }
    }

    .banner_img_container {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .navbar_container {
    .menu_container {
      width: 100%;
    }
  }

  .section.banner_container {
    .banner_text_container {
      .experience,
      .count {
        span {
          font-size: 2.5rem;
        }
      }

      .appointment_btn {
        font-size: 1rem;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .App {
    max-width: 100vw;
    overflow-x: hidden;
  }

  .banner_carousel {
    .banner_text_container {
      width: 70%;
      h1 {
        font-size: 2rem;
      }

      p {
        font-size: 1.2rem;
        padding: 10px 30px;
      }
    }
  }

  .navbar_container {
    position: relative;
    padding: 2rem 0;
    .menus {
      position: absolute;
      top: 0;
      right: -100%;
      width: 350px;
      height: 100vh;
      background-color: #38b492;
      padding-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      transition: 0.5s all ease;

      .menu_container {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 30px;
        width: 100%;
        height: 40%;
        margin-top: 20px;

        .menu_items {
          border-bottom: 1px solid white;
          width: 100%;
          a {
            font-size: 1.5rem;
            color: #000;
            background-color: transparent;
          }
        }
      }

      .closeIcon {
        margin-right: 40px;
      }
    }
    .menu--open {
      right: -25px;
      transition: 0.5s all ease;
    }

    .logo_container {
      width: 30%;
    }
  }

  .footer_container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .section_footer {
      width: 80%;
    }
    .section_content {
      width: 100%;

      .footer_menu {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;

        .footer_link {
          margin: 20px;
        }
      }
    }
  }

  .section.banner_container {
    flex-direction: column;
    justify-content: center;
    .banner_text_container {
      padding: 30px;
      width: 100%;
      text-align: center;
      .experience,
      .count {
        margin-top: 20px;
      }
    }

    .banner_img_container {
      width: 100%;
    }
  }

  .about_container {
    .text_container {
      .about_paragraph {
        width: 90%;
      }
    }
  }

  .section {
    .heading_container {
      h1 {
        font-size: 3rem;
      }
      p {
        font-size: 1.5rem;
        padding: 0 10px;
      }
    }
  }

  .service_container {
    .services_dropdown {
      width: 90%;
      .accord_subservice {
        width: 90%;
      }
    }
  }

  .services_main_container {
    .services_container {
      flex-direction: column-reverse !important;
      width: 100%;
      padding: 1rem;
      .left,
      .right {
        width: 100%;
        text-align: center !important;
      }
    }
  }

  .subservices_container {
    flex-direction: column-reverse !important;
    .left,
    .right {
      width: 100%;
      text-align: center !important;
    }
  }

  .contact_section {
    .contact_card {
      flex-direction: column;

      .left,
      .right {
        width: 100%;
      }
    }
  }

  .team_section {
    .team_container {
      width: 95%;
    }
  }
}

@media screen and (max-width: 768px) {
  .about_container {
    .text_container {
      justify-content: center;
      .title {
        font-size: 2.5rem;
        margin-bottom: 50px;
      }
      .about_paragraph {
        font-size: 1.6rem;
      }
    }
  }

  .team_section {
    h1 {
      padding: 0 10px;
      text-align: center;
      font-size: 3rem;
      margin-bottom: 80px;
    }
    .team_container {
      flex-direction: column;
      align-items: center;
      .team {
        width: 90%;
        padding: 0;
        margin-bottom: 30px;
      }
    }
  }

  .services_main_container,
  .subservice_container {
    .services_container {
      .subservice_title {
        font-size: 2.5rem;
        text-align: center;
      }
      .left {
        .title {
          h1 {
            font-size: 2rem;
          }
        }
      }
    }
  }

  .section.banner_container {
    padding: 0;
  }
}

@media screen and (max-width: 640px) {
  .achivement {
    width: 60%;

    .image {
      flex: 1;
    }
    .text {
      flex: 1;
    }
  }

  .section.banner_container {
    .banner_img_container {
      .cards_container {
        flex-direction: column;

        .card {
          width: 100%;
          margin-bottom: 30px;
          text-align: center;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .navbar_container {
    padding: 2rem 1rem;

    .logo_container {
      width: 50%;

      img {
        width: 40%;
      }
    }
  }

  .banner_carousel {
    .banner_text_container {
      display: none;
    }
  }

  .about_container {
    .text_container {
      justify-content: center;
      .title {
        font-size: 2rem;
        margin-bottom: 50px;
        padding: 0px 10px;
      }
      .about_paragraph {
        font-size: 1.6rem;
      }
    }
  }

  .footer_container {
    .section_footer {
      width: 90%;
      &:last-child {
        padding-bottom: 50px;
      }
    }
  }

  .achivement {
    width: 80%;

    .image {
      flex: 1;
    }
    .text {
      flex: 1;
    }
  }

  .contact_section {
    h1 {
      font-size: 3.5rem;
    }
    .contact_card {
      .left {
        align-items: center;
        justify-content: center;
        padding-top: 20px;
        .timing {
          width: 100%;
        }

        .text {
          margin-left: 0;
          margin-top: 10px;
        }
      }

      .contact,
      .email,
      .address {
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;
      }

      .timing {
        h2 {
          text-align: center;
        }

        .week {
          flex-direction: column;

          p {
            width: 100%;
          }
        }
      }
    }
  }

  .subservices_container {
    padding-left: 0;
    padding-right: 0;
  }

  .top_bar {
    .announcement_bar {
      justify-content: space-between;
      width: 95%;
      .email,
      .mobile {
        font-size: 1rem;

        img {
          width: 20px;
          margin-right: 10px;
        }
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .hamburger_menu {
    display: none;
  }
  .closeIcon {
    display: none;
  }
}
